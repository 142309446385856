@import "minima";

figcaption {
  text-align: center;
  color: darkgray;
}

.highlight {
  font-weight: bold;
  color: red;
}